
import { Component, Vue, Provide, Emit, Prop, Watch } from 'vue-property-decorator'
import { mobileRegExp } from '@/utils/reg'
import smsMixin from '@/mixins/sms'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import VerificationCode from '@/components/VerificationCode.vue'
import getCurrentQuery from '@/utils/querystrings'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'Abnormal',
  components: {
    'verification-code': VerificationCode
  },
  mixins: [smsMixin, GlobalMixin]
})
export default class Abnormal extends Vue {
  @Provide() public mobile: string = ''
  @Provide() public grantType: number = 9
  @Provide() public smsType: number = 7

  public created () {
    this.refresh()
  }
  public mounted () {
    (this as any).$children[0].sendCode()
  }

  @Emit()
  public async refresh () {
    this.mobile = decodeURIComponent(getCurrentQuery('id'))
    this.smsType = parseInt(getCurrentQuery('smsType')) || 3
    this.grantType = parseInt(getCurrentQuery('grantType')) || 4
  }

  @Emit()
  public goback () {
    this.$router.replace('/sms')
  }
}

